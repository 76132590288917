import { computed, ref } from 'vue';
import EditorTextarea from '@/modulos/extensoes/editor/EditorTextarea.vue';


let categorias: string[] = ["Métricas", "Portais", "Ferramentas", "Mais Leads", "Seo/Desempenho", "Layout", "CRM", "CRM Imobiliário", "Plataformas", "Migração"];

export const extensao_repository = new ExtensaoRepository()
export const account_extensao_repository = new AccountExtensaoRepository()

import atalhos_topo_image from '@/img/extensoes/atalhos-topo.svg?url'
import EditorAtalhosTopo from '@/modulos/extensoes/editor/EditorAtalhosTopo.vue';
extensao_repository.add({
    id: 'atalhos-topo',
    titulo: 'Atalhos Topo',
    descricao: 'Adicione facilmente atalhos no topo de seu site.',
    imagem: atalhos_topo_image,
    gratuito: true,
    categorias: ["Layout"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorAtalhosTopo
})


import gta_image from '@/img/extensoes/google-tag-manager.png'

extensao_repository.add({
    id: 'google-tag-manager',
    titulo: 'Google Tag Manager',
    descricao: 'Adicione facilmente o código do GTM em seu site.',
    imagem: gta_image,
    gratuito: true,
    categorias: ["Métricas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import facebook_pixel_image from '@/img/extensoes/facebook-pixel.png'
extensao_repository.add({
    id: "facebook-pixel",
    imagem: facebook_pixel_image,
    titulo: "Facebook Pixel",
    descricao: "Adicione o código Pixel do Facebook para mensurar os resultados.",
    gratuito: true,
    categorias: ["Métricas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import google_analytics_image from '@/img/extensoes/google-analytics.png'
extensao_repository.add({
    id: "google-analytics",
    imagem: google_analytics_image,
    titulo: "Google Analytics",
    descricao: "Configure o Google Analytics. Configure e personalize a medição de sites, dispositivos conectados à internet e apps para Web e dispositivos móveis.",
    gratuito: true,
    categorias: ["Métricas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import hotjar_image from '@/img/extensoes/hotjar.png'
extensao_repository.add({
    id: "Hotjar",
    imagem: hotjar_image,
    titulo: "Hotjar",
    descricao: "Coloque o código do Hotjar em seu site colando o código facilmente.",
    gratuito: true,
    categorias: ["Métricas"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

import pinterest_image from '@/img/extensoes/pinterest.png'
extensao_repository.add({
    id: "pinterest",
    imagem: pinterest_image,
    titulo: "Pinterest",
    descricao: "Adicione ao seu site o rastreamento das tags do pinterest.",
    gratuito: true,
    categorias: ["Métricas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

/*
import c2s_image from '@/img/extensoes/c2s.png'
extensao_repository.add({
    id: "c2s",
    imagem: c2s_image,
    titulo: "C2S",
    descricao: "Ative a cópia do e-mail para receber os leads dentro do CRM.",
    gratuito: true,
    categorias: ["CRM"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import bitrix_image from '@/img/extensoes/bitrix.png'
extensao_repository.add({
    id: "bitrix",
    imagem: bitrix_image,
    titulo: "Bitrix",
    descricao: "Ative a cópia do e-mail para receber os leads dentro do CRM.",
    gratuito: true,
    categorias: ["CRM"],
    valor_mensal: 0,
    valor_instalacao: 0
})
*/
import rd_station_image from '@/img/extensoes/rd-station.png'
extensao_repository.add({
    id: "rd-station",
    imagem: rd_station_image,
    titulo: "RD Station",
    descricao: "Adicione o código de monitoramento no site para permitir que o RD Station capture os dados.",
    gratuito: true,
    categorias: ["CRM"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

/*
import outros_crms_image from '@/img/extensoes/outros-crms.png'
extensao_repository.add({
    id: "outros-crms",
    imagem: outros_crms_image,
    titulo: "Outros CRM's",
    descricao: "Adicione o código de monitoramento do seu CRM ou cópia do e-mail.",
    gratuito: true,
    categorias: ["CRM"],
    valor_mensal: 0,
    valor_instalacao: 0
})
*/

import jivo_chat_image from '@/img/extensoes/jivo-chat.png'
extensao_repository.add({
    id: "jivo-chat",
    imagem: jivo_chat_image,
    titulo: "Jivochat",
    descricao: "Adicione este chat ao seu site apenas colocando o código fornecido.",
    gratuito: true,
    categorias: ["Chat"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

import tawkto_image from '@/img/extensoes/tawkto.png'
extensao_repository.add({
    id: "tawk-to",
    imagem: tawkto_image,
    titulo: "Tawk.to",
    descricao: "Adicione este chat ao seu site apenas colocando o código fornecido.",
    gratuito: true,
    categorias: ["Chat"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

import leadster_image from '@/img/extensoes/leadster.png'
extensao_repository.add({
    id: "leadster",
    imagem: leadster_image,
    titulo: "Leadster",
    descricao: "Adicione este chat ao seu site apenas colocando o código fornecido.",
    gratuito: true,
    categorias: ["Chat"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

import tolvnow_image from '@/img/extensoes/tolvnow.png'
extensao_repository.add({
    id: "tolv-now",
    imagem: tolvnow_image,
    titulo: "Tolv now",
    descricao: "Adicione este chat ao seu site apenas colocando o código fornecido.",
    gratuito: true,
    categorias: ["Chat"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

import chat_customizado_image from '@/img/extensoes/chat-customizado.png'
import { AccountExtensao } from './AccountExtensao';
import { AccountExtensaoRepository } from './AccountExtensaoRepository';
import { ExtensaoRepository } from './ExtensaoRepository';
extensao_repository.add({
    id: "chat-customizado",
    imagem: chat_customizado_image,
    titulo: "Chat",
    descricao: "Adicione este chat ao seu site apenas colocando o código fornecido.",
    gratuito: true,
    categorias: ["Chat"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorTextarea
})

/*
import compartilhar_image from '@/img/extensoes/compartilhar.png'
extensao_repository.add({
    id: "compartilhar",
    imagem: compartilhar_image,
    titulo: "Compartilhar",
    descricao: "Compartilhe o imóvel no Whatsapp, Facebook, X, Linkedin e e-mail.",
    gratuito: true,
    categorias: ["Ferramentas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import area_do_cliente_image from '@/img/extensoes/area-do-cliente.png'
extensao_repository.add({
    id: "area-do-cliente",
    imagem: area_do_cliente_image,
    titulo: "Área do cliente",
    descricao: "Crie um botão em destaque no topo e rodapé do seu site facilitando o acesso do seu cliente a segunda via de boletos, condomínio e etc. Apenas links externos.",
    gratuito: true,
    categorias: ["Ferramentas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import whatsapp_pre_formulario_image from '@/img/extensoes/whatsapp-pre-formulario.png'
extensao_repository.add({
    id: "whatsapp-pre-formulario",
    imagem: whatsapp_pre_formulario_image,
    titulo: "Whatsapp pré-formulário",
    descricao: "O visitante do seu site precisará gerar um lead antes de ser redirecionado para o whatsapp. Fundamental para não perder leads de desktop e computar conversões de campanhas.",
    gratuito: true,
    categorias: ["Mais Leads"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import bloqueio_de_fotos_image from '@/img/extensoes/bloqueio-de-fotos.png'
extensao_repository.add({
    id: "bloqueio-de-fotos",
    imagem: bloqueio_de_fotos_image,
    titulo: "Bloqueio de fotos",
    descricao: "Só poderá acessar mais fotos do imóvel mediante desbloqueio do mesmo através do preenchimento de um formulário.",
    gratuito: true,
    categorias: ["Mais Leads"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import tour_virtual_image from '@/img/extensoes/tour-virtual.png'
extensao_repository.add({
    id: "tour-virtual-360",
    imagem: tour_virtual_image,
    titulo: "Tour virtual 360º",
    descricao: "Ative esse recurso para colar o link das plataformas de tour virtual que utiliza.",
    gratuito: true,
    categorias: ["Ferramentas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import plantas_image from '@/img/extensoes/plantas.png'
extensao_repository.add({
    id: "plantas",
    imagem: plantas_image,
    titulo: "Plantas",
    descricao: "Galeria de fotos separada para plantas.",
    gratuito: true,
    categorias: ["Ferramentas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import depoimentos_image from '@/img/extensoes/depoimentos.png'
extensao_repository.add({
    id: "depoimentos",
    imagem: depoimentos_image,
    titulo: "Depoimentos",
    descricao: "Mostra depoimentos de clientes sobre a imobiliária ou da equipe como prova social.",
    gratuito: true,
    categorias: ["Ferramentas"],
    valor_mensal: 0,
    valor_instalacao: 0
})

import vinculo_corretor_image from '@/img/extensoes/vinculo-corretor.png'

extensao_repository.add({
    id: "vinculo-corretor",
    imagem: vinculo_corretor_image,
    titulo: "Vinculo corretor na URL",
    descricao: "Os corretores(as) poderão vincular qualquer imóvel ou todo o site através de um link, viabilizando criar campanhas exclusivas na imobiliária sem ter receito de perder leads.",
    gratuito: true,
    categorias: ["Ferramentas"],
    valor_mensal: 0,
    valor_instalacao: 0
})
*/

import vistasoft_image from '@/img/extensoes/vista.png'
import EditorVistaSoft from '@/modulos/extensoes/editor/EditorVistaSoft.vue';
let vistasoft_extensao = {
    id: "vistasoft",
    imagem: vistasoft_image,
    titulo: "Vista Soft",
    descricao: "Vista Soft",
    gratuito: true,
    categorias: ["CRM Imobiliário"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorVistaSoft
}


import jetimob_image from '@/img/extensoes/jetimob.webp'
import EditorJetimob from '@/modulos/extensoes/editor/EditorJetimob.vue';

let jetimob_extensao = {
    id: "jetimob",
    imagem: jetimob_image,
    titulo: "Jetimob",
    descricao: "Jetimob",
    gratuito: true,
    categorias: ["CRM Imobiliário"],
    valor_mensal: 0,
    valor_instalacao: 0,
    editor: EditorJetimob
}


export const account_extensoes = ref<AccountExtensao[]>([])
export const extensoes_instaladas = computed(() => {
    return account_extensoes.value.map((row) => row.extensao_id)
})

export const loadExtensoesInstaladas = async () => {
    const instaladas = await account_extensao_repository.search()
    let ids = instaladas.map(({ extensao_id }) => extensao_id)   
    
    if (ids.includes('jetimob')) {        
        extensao_repository.add(jetimob_extensao)
    }
    if (ids.includes('vistasoft')) {
        extensao_repository.add(vistasoft_extensao)
    }
    account_extensoes.value = instaladas
}

loadExtensoesInstaladas()
